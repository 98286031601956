import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import PostEntryTextArea from './PostEntryTextArea';

const PostEntryAuthGuard = () => {
    const { token } = useAuth(); // Assuming token is null when not logged in

    if (!token) {
        return (
            <div className="bg-gray-500 border border-gray-400 p-4 m-2 shadow mb-4 text-white text-s flex flex-col">
                <p className="flex-grow">Please sign up to post and vote!</p>
            </div>
        );
    }

    return (
        <div className="your-card-styling">
            <PostEntryTextArea />
        </div>
    );
}

export default PostEntryAuthGuard;
