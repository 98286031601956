// src/components/Header.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginModal from '../login/LoginModal';
import SignUpModal from '../login/SignUpModal';
import { useAuth } from '../AuthContext';
import AccountDropdown from './AccountDropdown';
import { logout, refreshAccessToken } from '../../api/auth';
import * as api from '../../api/api';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store';


const Header: React.FC = () => {
    const { token, setToken } = useAuth();
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
    const getUser = api.withTokenRefresh(api.getUser, token, setToken);
    // const phase = useSelector((state: RootState) => state.phase);


    useEffect(() => {
        async function refreshSession() {
          try {
            const token = await refreshAccessToken()
            setToken(token['access_token'])
          } catch (error) {
            console.log(error)
          }
        }
        
        refreshSession();
      }, []);

    const handleSignOut = async () => {
        console.log('onSignout')
        if (!token) throw new Error('No token found');
        const success = await logout(token);
        if (success) {
            setToken(null);
        } else {
            alert('Error logging out')
            setToken(null);
        }
    }

    const handleDelete = async () => {
        // handle account deletion logic
        console.log('onDelete')
        const user = await getUser(null);
        console.log(user)
    }

    return (
        <div className="sticky top-0 z-10">
            <header className="bg-gray-800 p-4 text-white shadow-md">
                <div className="container mx-auto flex justify-between items-center">
                    <h1 className="text-2xl font-bold">
                        <Link to="/">AllTalk</Link>
                    </h1>
                    <nav>
                        <ul className="flex space-x-4">
                            <li><Link to="/about" className="hover:text-gray-400">About</Link></li>
                            {!token && <li><button onClick={() => setLoginModalOpen(true)} className="hover:text-gray-400">Login</button></li>}
                            {!token && <li><button onClick={() => setSignUpModalOpen(true)} className="hover:text-gray-400">Signup</button></li>}
                            {token && <li><AccountDropdown onSignout={ handleSignOut } onDelete={ handleDelete }/></li>}
                        </ul>
                    </nav>
                </div>
            </header>
            <LoginModal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} />
            <SignUpModal isOpen={isSignUpModalOpen} onClose={() => setSignUpModalOpen(false)} />
        </div>
    );
}

export default Header;
