// src/app/store.js

import { configureStore } from '@reduxjs/toolkit';
import phaseReducer from './features/phase/phaseSlice';
import phaseMiddleware from './features/phase/phaseMiddleware';

const store = configureStore({
  reducer: {
    phase: phaseReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(phaseMiddleware)
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
