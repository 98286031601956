import React, { useState, useContext } from 'react';
import { useAuth } from '../AuthContext';

interface VoteButtonsProps {
    post_id: number;
    onVote: (value: number) => void;
}

const VoteButtons: React.FC<VoteButtonsProps> = ({post_id, onVote}) => {
    const [voteForClicked, setVoteForClicked] = useState(false);
    const [voteAgainstClicked, setVoteAgainstClicked] = useState(false);

    const handleVote = (type: number) => {
        if (type === 1) {
            setVoteForClicked(true);
            setTimeout(() => setVoteForClicked(false), 300);
            onVote(1);
        } else {
            setVoteAgainstClicked(true);
            setTimeout(() => setVoteAgainstClicked(false), 300);
            onVote(-1);
        }
    }

    return (
        <div className="space-x-2">
            <button 
                onClick={() => handleVote(1)}
                disabled={voteForClicked || voteAgainstClicked}
                className={`py-1 px-3 rounded transition duration-500 ease-in-out 
                            ${voteForClicked ? 'bg-green-500' : 'bg-gray-600 hover:bg-gray-500'}`}
            >
                Vote For
            </button>
            <button 
                onClick={() => handleVote(-1)}
                disabled={voteForClicked || voteAgainstClicked}
                className={`py-1 px-3 rounded transition duration-500 ease-in-out 
                            ${voteAgainstClicked ? 'bg-green-500' : 'bg-gray-600 hover:bg-gray-500'}`}
            >
                Vote Against
            </button>
        </div>
    )
};

export default VoteButtons;