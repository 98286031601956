import { refreshAccessToken } from './auth';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = BASE_URL;


export const withTokenRefresh = <T, U>(
        apiMethod: (data: T, token: string|null) => Promise<U>,
        token: string | null,
        setToken: (token: string | null) => void
    ) => {
    return async (data: T): Promise<U> => {
        try {
            return await apiMethod(data, token);
        } catch (error : any) {
            if (error.response && error.response.status === 401) {
                try {
                    const token_data = await refreshAccessToken();
                    setToken(token_data.access_token);
                    // how do we set the auth token here?
                    return await apiMethod(data, token_data.access_token);
                } catch (refreshError) {
                    setToken(null)
                    // window.location.href = '/login';
                    throw new Error("Authentication error"); // or handle it more gracefully
                }
            } else {
                throw error;
            }
        }
    };
};


export const getUser = async (data: null, token: string | null) => {
    if (!token) {
        throw new Error("No token provided");
    }
    const userResponse = await axios.get(
        `${BASE_URL}/user/`,
        {headers: {'Authorization': `Bearer ${token}`}}
    );
    return userResponse.data;
};


export const getAcceptedPosts = async (from_index: number, to_index: number) => {
    const url = `${BASE_URL}/posts/accepted?from_index=${from_index}&to_index=${to_index}`;
    const acceptedPostsResponse = await axios.get(url);
    return acceptedPostsResponse.data;
};


export const getPhase = async () => {
    const url = `${BASE_URL}/state`;
    const acceptedPostsResponse = await axios.get(url);
    return acceptedPostsResponse.data;
}


export const getSuggestPost = async (data: null, token: string | null) => {
    const url = `${BASE_URL}/posts/suggested`;
    const suggestedPostsResponse = await axios.get(url,
        {headers: {'Authorization': `Bearer ${token}`}}
    );
    return suggestedPostsResponse.data;
};


export const postSuggestedPost = async (data: {content: string}, token: string | null) => {
    const url = `${BASE_URL}/posts/suggest`;
    const suggestedPostsResponse = await axios.post(url, data,
        {headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }}
    );
    return suggestedPostsResponse.data;
}


export const postVote = async (data: {post_id: number, vote_value: number}, token: string | null) => {
    const url = `${BASE_URL}/posts/vote`;
    const suggestedPostsResponse = await axios.post(url, data,
        {headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }});
    return suggestedPostsResponse.data;
}
