import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import PostVoteToggle from './PostVoteToggle';
import VoteButtons from './VoteButtons';
import * as api from '../../api/api';
import { fetchPhase } from '../phase/phaseSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';


const PostEntryTextArea = () => {
    const { token, setToken } = useAuth();
    const [postContent, setPostContent] = useState("");
    const [lastPhase, setLastPhase] = useState("");
    const [suggestedPost, setSuggestedPost] = useState({round_id: null, id: null, content: ""});
    const getSuggestPost = api.withTokenRefresh(api.getSuggestPost, token, setToken);
    const postSuggestedPost = api.withTokenRefresh(api.postSuggestedPost, token, setToken);
    const postVote = api.withTokenRefresh(api.postVote, token, setToken);
    const dispatch = useDispatch();
    const phase = useSelector((state: RootState) => state.phase);

    useEffect(() => {
        if (phase.phase === 'vote' && phase.phase !== lastPhase) {
            fetchSuggestedPost();
            setLastPhase(phase.phase);
        }
    }, [phase]);

    const fetchSuggestedPost = async () => {
        try {
            const data = await getSuggestPost(null);
            setSuggestedPost(data);
        } catch (error: any) {
            console.log(error)
            if (error.response && error.response.data.detail === 'Incorrect Phase') {
                console.log('Incorrect Phase')
            }
        }
    }

    const handlePost = async () => {
        const data = await postSuggestedPost({content: postContent})
        console.log(data)
        if (data.status === 'success') {
            alert("Post submitted successfully");
            setPostContent("");
        }
    };

    const handleVote = async (value: number) => {
        if (!suggestedPost.id) return;
        try {
            await postVote({post_id: suggestedPost.id, vote_value: value});
        } catch (error: any) {
            if (error.response && error.response.data.detail === 'Incorrect Phase') {
                alert('Voting phase has now ended.')
                dispatch(fetchPhase())
            }
        }
        await fetchSuggestedPost();
    };

    return (
        <div className="your-card-styling">
            {phase.phase === 'suggest' ? (
                <div className="bg-gray-700 border border-gray-400 p-4 m-2 shadow mb-4 text-white text-s flex flex-col">
                    <div className="flex flex-col space-y-2">
                        <textarea 
                            className="bg-gray-600 text-white p-2 rounded mb-2 flex-grow resize-y" 
                            value={postContent} 
                            onChange={(e) => setPostContent(e.target.value)}
                            rows={4}
                            placeholder="Write your post..."
                        />

                        <div className="flex items-center justify-between space-x-4">
                            <PostVoteToggle
                                mode={phase.phase}
                                exp={ phase.exp ? new Date(phase.exp) : new Date() }
                            />
                            <button 
                                className={`py-1 px-3 rounded transition bg-gray-600 hover:bg-gray-500`}
                                onClick={ handlePost }
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="bg-gray-700 border border-gray-400 p-4 m-2 shadow mb-4 text-white text-s flex flex-col">
                    <div className="flex flex-col space-y-2">
                        <textarea 
                            className="bg-gray-600 text-white p-2 rounded mb-2 flex-grow resize-y"
                            value={ suggestedPost.content }
                            rows={4}
                            readOnly
                            disabled
                        />
                        
                        <div className="flex justify-between items-center space-x-4">
                            <PostVoteToggle
                                mode={phase.phase}
                                exp={ phase.exp ? new Date(phase.exp) : new Date() }
                            />
                            <VoteButtons post_id={1} onVote={handleVote}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PostEntryTextArea;
