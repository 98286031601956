import axios, { Axios } from 'axios';
import { setCookie, getCookie, deleteCookie } from '../cookies';

const BASE_URL = process.env.REACT_APP_API_URL;

interface SignupData {
    name: string;
    email: string;
    password: string;
}

export const signup = async (userData: SignupData) => {
  try {
    const signupResponse = await axios.post(`${BASE_URL}/user`, userData);
    const loginResponse = await axios.post(`${BASE_URL}/user/authenticate`, userData);
    return loginResponse.data;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

interface LoginData {
    email: string;
    password: string;
}

export const login = async (userData: LoginData) => {
    try {
      console.log(userData)
      const response = await axios.post(`${BASE_URL}/user/authenticate`, userData);
      setCookie(
        'refresh_token',
        response.data['refresh_token'],
        response.data['refresh_token_expires_days']
      )
      return response.data;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
};

export const logout = async (token: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/user/revoke_auth`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    deleteCookie('refresh_token')
    console.log(response.data.status)

    if (response.status === 200 && response.data.status === "success") {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error logging out:", error);
    return false;
  }
};

export const refreshAccessToken = async () => {
  try {
    const refresh_token = getCookie('refresh_token')
    console.log(refresh_token)
    const response = await axios.post(`${BASE_URL}/user/refresh_auth`, {}, {
      headers: {
        'Authorization': `Bearer ${refresh_token}`
      }
    });
    
    setCookie(
      'refresh_token',
      response.data['refresh_token'],
      response.data['refresh_token_expires_days']
    )
    console.log(response.data)
    return response.data
  } catch (error) {
    deleteCookie('refresh_token')
    throw new Error("Failed to refresh token");
  }
};
