// src/features/phase/phaseSlice.js
import { createSlice } from '@reduxjs/toolkit';


interface PhaseState {
    phase: string | null;
    exp: string | null;
    isStale: boolean;
    timerId: null,
    round_id: number | null,
}

const initialState: PhaseState = {
    phase: null,
    exp: null,
    isStale: true,
    timerId: null,
    round_id: null,
}


const phaseSlice = createSlice({
  name: 'phase',
  initialState,
  reducers: {
        setPhase: (state, action) => {
            state.round_id = action.payload.round_id;
            state.phase = action.payload.phase;
            state.exp = action.payload.exp;
            state.isStale = false;
        },
        markPhaseStale: (state) => {
            state.isStale = true;
        },
        setTimerId: (state, action) => {
            if (state.timerId) {
                clearTimeout(state.timerId);
            }
            state.timerId = action.payload;
        },
        fetchPhase: () => {},
    },
});


export const { setPhase, markPhaseStale, fetchPhase, setTimerId } = phaseSlice.actions;
export default phaseSlice.reducer;
