import React, { useState, useRef, useEffect } from 'react';
import * as api from '../../api/api';
import { useAuth } from '../AuthContext';

interface Props {
  onSignout: () => void;
  onDelete: () => void;
}

interface User {
  id: number;
  name: string;
  email: string;
  posts: number[];
}

const AccountDropdown: React.FC<Props> = ({ onSignout, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const { token, setToken } = useAuth();
  const getUser = api.withTokenRefresh(api.getUser, token, setToken);

  useEffect(() => {
    async function fetchUser() {
      try {
        const userDetails: User = await getUser(null);
        setUser(userDetails);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    }
    
    fetchUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button className="hover:text-gray-400" onClick={() => setIsOpen(!isOpen)}>
        Account
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
          {user && (
            <div className="px-4 py-2 bg-gray-200 border-b">
              <div className="font-bold text-gray-600">{user.name}</div>
              <div className="text-sm text-gray-600">{user.email}</div>
            </div>
          )}
          <button className="block w-full px-4 py-2 text-left hover:bg-gray-300 text-black" onClick={onSignout}>Sign out</button>
          <button className="block w-full px-4 py-2 text-left hover:bg-gray-300 text-black" onClick={() => { /* Navigate to posts */ }}>Posts</button>
          <button className="block w-full px-4 py-2 text-left hover:bg-gray-300 text-black" onClick={onDelete}>Delete Account</button>
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
