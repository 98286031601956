// src/App.tsx

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Header from './features/header/Header';
import About from './features/About';
import { AuthProvider } from './features/AuthContext';
import Feed from './features/feed/Feed';
import { Provider } from 'react-redux';
import store from './store';
import { Initializer } from './features/Initializer';



function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Initializer />
        <Router>
          <div className="App bg-gray-900 min-h-screen">
            <Header />
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/" element={<Feed />}/>
            </Routes>
          </div>
        </Router>
      </Provider>
    </AuthProvider>
  );
}

export default App;
