import { Middleware } from '@reduxjs/toolkit';
import { setPhase, markPhaseStale, fetchPhase, setTimerId } from './phaseSlice';
import * as api from '../../api/api';


const computeDelay = (exp: string) => {
    const futureDate = new Date(exp);
    const now = new Date();
    const delay = futureDate.getTime() - now.getTime();
    return delay;
}

const phaseMiddleware: Middleware = store => next => action => {
    if (action.type === 'phase/fetchPhase') {
        api.getPhase().then(data => {
            store.dispatch(setPhase(data));
            let timeDiff = computeDelay(data.exp);
            if (timeDiff < 0) {
                timeDiff = 5000;
                store.dispatch(markPhaseStale());
            }
            const newTimerId = setTimeout(() => {
                store.dispatch(markPhaseStale());
                store.dispatch(fetchPhase());
            }, timeDiff);
            store.dispatch(setTimerId(newTimerId));
        });
    }
  
    return next(action);
  };


export default phaseMiddleware;