// src/components/Feed.tsx

import React, { useState, useEffect } from 'react';
import PostCard from './PostCard';
import { getAcceptedPosts } from '../../api/api';
import InfiniteScroll from 'react-infinite-scroller';
import PostEntryAuthGuard from './PostEntryAuthGuard';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';


interface Post {
    content: string;
    round_id: number;
}

const Feed: React.FC = () => {
    const [posts, setPosts] = useState<Map<number, Post>>(new Map());
    const [hasMore, setHasMore] = useState(true);
    const [i, setI] = useState(0);
    const phase = useSelector((state: RootState) => state.phase);

    const fetchMorePosts = () => {
        if (phase.round_id){
            if (phase.round_id - i < 0) {
                setHasMore(false);
                return;
            }
            getAcceptedPosts(phase.round_id - i - 10, phase.round_id - i).then(newPosts => {
                setI(i + 10);
                if (newPosts.posts.length === 0) {
                    setHasMore(false);
                    return;
                }
                setPosts(prevPosts => {
                    const updatedPosts = new Map(prevPosts);
                    newPosts.posts.forEach((post: Post) => updatedPosts.set(post.round_id, post));
                    return updatedPosts; 
                });
            }).catch(error => {
                if (error.response && error.response.data.detail === "requested range is negative") {
                    setHasMore(false);
                }
            });
        }
    };

    useEffect(() => {
        if (!phase.round_id) return;
        getAcceptedPosts(phase.round_id - 3, phase.round_id).then(newPosts => {
            setPosts(prevPosts => {
                const updatedPosts = new Map(prevPosts);
                newPosts.posts.forEach((post: Post) => updatedPosts.set(post.round_id, post));
                return updatedPosts; 
            });
        })
    }, [phase.round_id]);

    const sortedPosts = Array.from(posts).sort((a, b) => b[0] - a[0]);


    return (
        <div className="flex h-full justify-center mt-6 overflow-y-auto">
            <div className="w-full md:w-1/2 min-h-screen bg-gray-600">
            <InfiniteScroll
                pageStart={0}
                loadMore={fetchMorePosts}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
            >
                <PostEntryAuthGuard />
                {sortedPosts.map((post, index) => (
                    <PostCard
                        key={index}
                        content={post[1].content}
                        round_id={post[1].round_id}
                    />
                ))}
            </InfiniteScroll>
            </div>
        </div>
    );
}

export default Feed;
