// src/components/Header.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhase } from './phase/phaseSlice';
import { RootState } from '../store';


export const Initializer: React.FC = () => {
    const dispatch = useDispatch();
    const phase = useSelector((state: RootState) => state.phase);

    useEffect(() => {
        dispatch(fetchPhase());
    }, []);

    return (<div />);
}
