// src/components/About.tsx

import React from 'react';

const About: React.FC = () => {
    return (
        <div className="p-6 text-white"> {/* Removed bg-gray-900 */}
            <div className="max-w-screen-md mx-auto">
                <h2 className="text-3xl font-semibold mb-4">About Us</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. 
                    Donec in efficitur leo. Vivamus gravida, quam vel dictum imperdiet, turpis arcu facilisis elit, 
                    vel egestas libero turpis non tortor. Duis aliquam bibendum libero a efficitur. Maecenas 
                    non lorem quis tellus placerat varius. Nulla facilisi.
                </p>
            </div>
        </div>
    );
}

export default About;
