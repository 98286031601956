// src/components/PostCard.tsx
import React from 'react';

interface PostCardProps {
    content: string;
    round_id: number;
}

const PostCard: React.FC<PostCardProps> = ({ content, round_id }) => {
    return (
        <div className="bg-gray-700 border border-gray-400 p-4 m-2 shadow mb-4 text-white text-s flex flex-col">
            <p className="flex-grow">{content}</p>
            <div className="mt-2 text-white text-xs text-right">{round_id}</div>
        </div>
    );
}

export default PostCard;
