// src/components/LoginModal.tsx

import React, { useRef, useEffect, useState } from 'react';
import { signup } from '../../api/auth';
import { useAuth } from '../AuthContext';


interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const SignUpModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const { setToken } = useAuth();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== passwordCheck) {
            alert("Passwords do not match");
            return;
        }

        try {
          const data = await signup({ name, email, password });
          console.log(data);
          setToken(data['access_token']);
          // Should also set refresh token in httpOnly cookie
          onClose();
        } catch (error : any) {
          console.error("Error while signing up:", error.response.data['detail']);
          alert(error.response.data['detail']);
        }
      };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'passwordCheck':
                setPasswordCheck(value);
                break;
            default:
                break;
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div ref={modalRef} className="bg-gray-800 rounded p-6 w-96 text-white">
                <h2 className="text-2xl font-bold mb-4">Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block mb-2">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleChange}
                            className="p-2 w-full rounded text-black"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            className="p-2 w-full rounded text-black"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block mb-2">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            className="p-2 w-full rounded text-black"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="passwordCheck" className="block mb-2">Confirm Password:</label>
                        <input
                            type="password"
                            id="passwordCheck"
                            name="passwordCheck"
                            value={passwordCheck}
                            onChange={handleChange}
                            className="p-2 w-full rounded text-black"
                        />
                    </div>

                    <div className="text-right">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SignUpModal;
