import React, { useEffect, useState } from 'react';
import CountdownTimer from './CountdownTimer';


interface PostVoteToggleProps {
    mode: string | null;
    exp: Date;
}

const PostVoteToggle: React.FC<PostVoteToggleProps> = ({mode, exp}) => {
    const calculateTimeLeft = (till: Date) => {
        const now = new Date();
        const difference = exp.getTime() - now.getTime();
        return Math.max(Math.floor(difference / 1000), 0);  // Convert difference to seconds, and ensure non-negative.
    }

    const [secondsLeft, setSecondsLeft] = useState(0);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSecondsLeft(calculateTimeLeft(exp));
        }, 1000);

        return () => clearTimeout(timerId);  // This will clear the timer if the component is unmounted.
    }, [exp, secondsLeft]);

    const minutes = String(Math.floor(secondsLeft / 60)).padStart(2, '0');
    const seconds = String(secondsLeft % 60).padStart(2, '0');

    return (
        <div className="flex border border-gray-300 rounded-full overflow-hidden">
            <button 
                disabled
                className={`flex-grow px-4 py-1 ${mode === 'suggest' ? 'bg-green-500 text-white' : 'text-gray-500'}`}
            >
                Suggest Phase
            </button>

            <div className="flex-shrink-0 bg-300 border-l border-r border-gray-300 px-4 py-1">
                {secondsLeft ? minutes + ':' + seconds : '--:--'}
            </div>

            <button 
                disabled
                className={`flex-grow px-4 py-1 ${mode === 'vote' ? 'bg-green-500 text-white' : 'text-gray-500'}`}
            >
                Vote Phase
            </button>
        </div>

    ); 
}

export default PostVoteToggle;